import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static targets = [
    "status",
    "estimateStatus",
    "estimateStatusHistory",
    "historyStatusDropdown",
    "userName",
    "bomWoInfo",
    "workOrder",
    "quantity",
    "difficulty",
    "margin",
    "unitPrice",
    "includeUnitPrice",
    "total",
    "itemDisplayInTotals",
    "row",
    "groupSubtotal",
    "quantity",
    "margin",
    "unitPrice",
    "total",
  ];

  submitForm(event) {
    var field = event.target;
    this.element.form.requestSubmit();
    this.currentValue = field.value;
  }

  toggleWorkOrderInfoField(event) {
    const estimate_id = event.target.dataset.value;
    const selected_status = event.target.value;

    this.updateBomWoInfo(selected_status, estimate_id);
    this.updateUserField(selected_status, estimate_id);
  }

  toggleWorkOrderField(event) {
    const estimate_id = event.target.dataset.value;

    this.setBomWoInfoBlock(estimate_id).classList.add("d-none");
    this.setWorkOrderField(estimate_id).classList.remove("d-none");
  }

  showeWorkOrderInfo(event) {
    const estimate_id = event.target.dataset.value;

    this.setBomWoInfoBlock(estimate_id).classList.remove("d-none");
    this.setWorkOrderDropdown(estimate_id).value = "";
    this.setWorkOrderField(estimate_id).classList.add("d-none");
  }

  updateBomWoInfo(selected_status, estimate_id) {
    if (selected_status === "confirmed") {
      this.setBomWoInfoBlock(estimate_id).classList.remove("d-none");
    } else {
      this.setBomWoInfoBlock(estimate_id).classList.add("d-none");
      this.setWorkOrderField(estimate_id).classList.add("d-none");
    }
  }

  updateUserField(selected_status, estimate_id) {
    if (selected_status === "approved" || selected_status === "declined") {
      this.setUserField(estimate_id).classList.remove("d-none");
      this.setByLabel(estimate_id);
      this.updateLabel(estimate_id, selected_status);
    } else {
      this.setUserField(estimate_id).classList.add("d-none");
      this.setByField(estimate_id).value = "";
      this.setDateField(estimate_id).value = new Date()
        .toISOString()
        .split("T")[0];
    }
  }

  updateLabel(estimate_id, selected_status) {
    const byLabelElement = this.setByLabel(estimate_id);

    // Change the text content based on some condition
    if (byLabelElement) {
      if (selected_status === "approved") {
        byLabelElement.textContent = "Approved by"; // Change text for approved status
      } else if (selected_status === "declined") {
        byLabelElement.textContent = "Declined by"; // Change text for declined status
      }
    }
  }

  setBomWoInfoBlock(estimate_id) {
    return this.element.querySelector("#wo_bom_info_" + estimate_id);
  }

  setWorkOrderField(estimate_id) {
    return this.element.querySelector("#existing_wo_" + estimate_id);
  }

  setWorkOrderDropdown(estimate_id) {
    return this.element.querySelector("#work_order_dropdown_" + estimate_id);
  }

  setUserField(estimate_id) {
    return this.element.querySelector("#user_field_estimate_" + estimate_id);
  }

  setByField(estimate_id) {
    return this.element.querySelector("#by_estimate_" + estimate_id);
  }

  setDateField(estimate_id) {
    return this.element.querySelector("#date_estimate_" + estimate_id);
  }

  setByLabel(estimate_id) {
    return this.element.querySelector("#by_label_" + estimate_id);
  }

  // Estimate Template JS

  toggleVisibility(event) {
    event.preventDefault();
    const shouldShow = event.target.closest("button").dataset.displayInTotals;
    const displayInTotalField = this.element.querySelector(
      ".item_display_in_totals",
    );

    displayInTotalField.value = shouldShow;

    // Toggle button visibility
    event.target.parentNode.parentElement
      .querySelector(".show_item")
      .classList.toggle("d-none");
    event.target.parentNode.parentElement
      .querySelector(".hide_item")
      .classList.toggle("d-none");
  }

  // Calculate the total when any of the values change
  updateItemTotal(event) {
    const row = event.target.closest("tr");
    const quantity = parseFloat(row.querySelector(".quantity").value) || 0;
    const margin = parseFloat(row.querySelector(".margin").value) || 0;
    const unitPrice = row.querySelector(".unitPrice");
    const includeUnitPrice = row.querySelector(".includeUnitPrice").checked;
    const productSellingPrice = parseFloat(
      row.querySelector("#product_selling_price").value,
    );
    const productShippingCost = parseFloat(
      row.querySelector("#product_shipping_cost").value,
    );
    const isCustomItem = row.querySelector(".custom_item").value;

    // Calculate total price based on the formula
    let subtotal = 0.0;

    if (includeUnitPrice) {
      if (isCustomItem == "true") {
        subtotal = quantity * parseFloat(unitPrice.value) || 0.0;
      } else {
        subtotal =
          quantity *
            (productSellingPrice + (productSellingPrice * margin) / 100) +
          productShippingCost;
      }
    } else {
      subtotal = 0.0;
    }

    // Update the total in the UI
    row.querySelector(".total").textContent =
      `$${new Intl.NumberFormat().format(subtotal.toFixed(2))}`;
    unitPrice.textContent = `$${new Intl.NumberFormat().format(subtotal.toFixed(2))}`;

    // Optionally, update the hidden field for display_in_totals if needed
    const displayInTotalsField = row.querySelector(".item_display_in_totals");
    displayInTotalsField.value = includeUnitPrice ? "true" : "false";

    // Now update the group totals for all rows
    this.calculatetotal();
  }

  // Calculate and update the group subtotal
  async calculatetotal() {
    let groupSubtotal = 0.0;
    let totalLabor = 0.0;
    const groupSubTotalField = document.getElementById("group____subtotal");
    const groupLaborField = document.getElementById("group_labor");
    const rows = document.querySelectorAll(".template_item");

    // Iterate through all rows and sum the subtotals
    rows.forEach((row) => {
      const isCustomItem = row.querySelector(".custom_item").value;
      const subtotalText = row.querySelector(".total").textContent;
      const subtotal = parseFloat(subtotalText.replace(/[,$]/g, "")) || 0.0; // Remove commas and parse
      groupSubtotal += subtotal;

      // If isCustomItem is 'true', skip the labor calculation
      if (isCustomItem == "true") {
        return;
      }

      const laborText = row.querySelector(".difficulty").value;
      const quantity = parseFloat(row.querySelector(".quantity").value) || 0.0;
      const labor = (parseFloat(laborText.replace(/,/g, "")) || 0.0) * quantity; // Remove commas and parse
      totalLabor += labor;
    });

    // Update the displayed group subtotal
    if (groupSubTotalField) {
      groupSubTotalField.textContent = `$${new Intl.NumberFormat().format(
        groupSubtotal.toFixed(2),
      )}`;
    }

    // Update the displayed group labor
    if (groupLaborField) {
      groupLaborField.textContent = this.time_conversion(totalLabor.toFixed(2)); // Use your time conversion function
    }
  }

  time_conversion(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (hours === 0) {
      return `${remainingMinutes} mins`;
    } else {
      return `${hours} hours ${remainingMinutes} mins`;
    }
  }

  copyTemplateName() {
    const groupNameInput = this.element.querySelector('[name="group_name"]');
    const templateNameInput = this.element.querySelector(
      '[name="template_name"]',
    );

    if (groupNameInput && templateNameInput) {
      groupNameInput.value = templateNameInput.value;
    }
  }

  // copyTemplateDescription() {
  //   // Find the Trix editor and the hidden input associated with it
  //   const trixEditor = this.element.querySelector(
  //     "trix-editor#group_description",
  //   );
  //   const templateDescriptionInput = this.element.querySelector(
  //     '[name="template_description"]',
  //   );

  //   if (trixEditor && templateDescriptionInput) {
  //     // Access the Trix editor's internal editor object
  //     const editor = trixEditor.editor;

  //     // Insert the content from the template_description input into the Trix editor
  //     editor.setSelectedRange([0, 0]); // Optional: reset cursor position
  //     editor.insertHTML(templateDescriptionInput.value); // Insert the content from template_description

  //     // Manually trigger the 'input' event to update the hidden input field
  //     const inputEvent = new Event("input", { bubbles: true });
  //     trixEditor.dispatchEvent(inputEvent);
  //   }
  // }

  // Clone template Item
  cloneItem(event) {
    event.preventDefault();

    const itemId = event.currentTarget.dataset.id;
    const itemRow = document.getElementById(itemId);
    const itemData = this.getItemData(itemRow);

    // Send the data to the backend using fetch
    this.sendRequest(
      itemData,
      "/estimates/clone_template_item",
      "Error cloning item:",
    );
  }

  getItemData(itemRow) {
    // Extract the data from the item (for example, custom_name, quantity, price, total, labor total)
    return {
      custom_name:
        itemRow.querySelector('[name="temporary_item[custom_name]"]')?.value ||
        "",
      quantity:
        itemRow.querySelector('[name="temporary_item[quantity]"]')?.value || "",
      unit_price:
        itemRow.querySelector('[name="temporary_item[unit_price]"]')?.value ||
        "",
      margin:
        itemRow.querySelector('[name="temporary_item[margin]"]')?.value || "",
      difficulty:
        itemRow.querySelector('[name="temporary_item[difficulty]"]')?.value ||
        "",
      display_in_total:
        itemRow.querySelector(".item_display_in_totals")?.value || "",
      product_id: itemRow.querySelector(".linked_product")?.value || "",
      include_price:
        itemRow.querySelector(".includeUnitPrice")?.checked || false,
      group_subtotal_field: parseFloat(
        document
          .getElementById("group____subtotal")
          ?.textContent.replace(/[,$]/g, "") || 0,
      ),
      group_labor_field: parseFloat(
        document.getElementById("group_labor")?.textContent.replace(/,/g, "") ||
          0,
      ),
      added_items:
        document
          .getElementsByClassName("added_template_product_ids")[0]
          ?.value.split(" ")
          .map(Number) || [],
    };
  }

  async sendRequest(itemData, request_url, error_message) {
    try {
      // Send the data to the backend using fetch
      const response = await fetch(request_url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
        },
        body: JSON.stringify(itemData),
      });

      // Check if the response is ok
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      // Get the response text and insert it into the DOM
      const text = await response.text();
      document.body.insertAdjacentHTML("beforeend", text); // Insert the new HTML

      // Defer the call to `calculatetotal` until after the DOM has been rendered
      requestAnimationFrame(async () => {
        await this.calculatetotal();
      });
    } catch (error) {
      console.error(error_message, error);
    }
  }

  // Clone template Item
  deleteItem(event) {
    event.preventDefault();

    const itemId = event.currentTarget.dataset.id;
    const itemRow = document.getElementById(itemId);
    const itemData = this.getDeleteItemData(itemRow, itemId);

    // Send the data to the backend using fetch
    this.sendRequest(
      itemData,
      "/estimates/remove_template_item",
      "Error deleting item:",
    );
  }

  replaceItem(event) {
    event.preventDefault();

    const itemId = event.target.closest("button").dataset.id;
    const domId = event.target.closest("button").dataset.domId;
    const productId = event.target.closest("button").dataset.productId;
    const itemRow = document.getElementById(domId);

    const itemData = this.getReplaceItemData(itemRow, itemId, productId, domId);

    // Send the data to the backend using fetch
    this.sendRequest(
      itemData,
      "/estimates/replace_template_item",
      "Error replacing item:",
    );
  }

  getDeleteItemData(itemRow, rowId) {
    return {
      product_id: itemRow.querySelector(".linked_product")?.value || "",
      row_id: rowId || "",
      group_subtotal_field: parseFloat(
        document
          .getElementById("group____subtotal")
          ?.textContent.replace(/[,$]/g, "") || 0,
      ),
      group_labor_field: parseFloat(
        document.getElementById("group_labor")?.textContent.replace(/,/g, "") ||
          0,
      ),
      added_items:
        document
          .getElementsByClassName("added_template_product_ids")[0]
          ?.value.split(" ")
          .map(Number) || [],
      difficulty:
        itemRow.querySelector('[name="temporary_item[difficulty]"]')?.value ||
        0,
      unit_price:
        itemRow.querySelector(".unitPrice").textContent.replace(/[,$]/g, "") ||
        0,
      item_id: itemRow.querySelector(".item_id").value,
    };
  }

  getReplaceItemData(itemRow, replace_item_id, product_id, dom_id) {
    return {
      custom_name:
        itemRow.querySelector('[name="temporary_item[custom_name]"]')?.value ||
        "",
      quantity:
        itemRow.querySelector('[name="temporary_item[quantity]"]')?.value || "",
      unit_price:
        itemRow.querySelector('[name="temporary_item[unit_price]"]')?.value ||
        "",
      margin:
        itemRow.querySelector('[name="temporary_item[margin]"]')?.value || "",
      difficulty:
        itemRow.querySelector('[name="temporary_item[difficulty]"]')?.value ||
        "",
      display_in_total:
        itemRow.querySelector(".item_display_in_totals")?.value || "",
      include_price:
        itemRow.querySelector(".includeUnitPrice")?.checked || false,
      group_subtotal_field: parseFloat(
        document
          .getElementById("group____subtotal")
          ?.textContent.replace(/[,$]/g, "") || 0,
      ),
      group_labor_field: parseFloat(
        document.getElementById("group_labor")?.textContent.replace(/,/g, "") ||
          0,
      ),
      added_items:
        document
          .getElementsByClassName("added_template_product_ids")[0]
          ?.value.split(" ")
          .map(Number) || [],
      replace_item_id: replace_item_id,
      product_id: product_id,
      dom_id: dom_id,
    };
  }

  addItem(event) {
    event.preventDefault();

    const add_item_url = event.target.closest("form").action;
    const product_id = event.target.closest("button").dataset.productId;
    const itemData = { product_id: product_id };

    // Send the data to the backend using fetch
    this.sendRequest(itemData, add_item_url, "Error while adding item:");
  }

  validateTemlateForm(event) {
    this.clearErrors();

    const nameField = document.getElementById("template_name_field");
    let isValid = true;

    if (!nameField.value.trim()) {
      this.showError("templateNameError", "Template Name is required.");
      isValid = false;
    } else {
      this.hideError("templateNameError");
    }

    if (!isValid) {
      event.preventDefault();
    }
  }

  showError(errorId, message) {
    const errorElement = document.getElementById(errorId);
    if (errorElement) {
      errorElement.textContent = message;
      errorElement.classList.remove("d-none");
    }
  }

  hideError(errorId) {
    const errorElement = document.getElementById(errorId);
    if (errorElement) {
      errorElement.textContent = "";
      errorElement.classList.add("d-none");
    }
  }

  clearErrors() {
    // Clear previous error messages
    const errorElements = document.querySelectorAll(".template-errors");
    errorElements.forEach((el) => {
      el.textContent = "";
      el.classList.add("d-none");
    });
  }

  // Method to update the selected checkboxes array and URL
  updateSelectedCheckboxes(event) {
    const checkedCheckboxes = document.querySelectorAll(
      ".item-checkbox:checked",
    );
    const selectedTemplateIds = Array.from(checkedCheckboxes).map(
      (checkbox) => checkbox.dataset.templateId,
    );

    // Update the link with selected checkboxes
    this.updateLink(selectedTemplateIds);
  }

  updateLink(selectedTemplateIds) {
    const link = document.getElementById("tags-link");
    const queryString = `?template_ids=${selectedTemplateIds.join(",")}`;
    const baseUrl = link.getAttribute("href").split("?")[0];
    const updatedUrl = baseUrl + queryString;

    link.setAttribute("href", updatedUrl);
  }

  submitTagForm() {
    const form = document.getElementById("estimate-tags-form");
    if (form) {
      form.submit();
    }
  }

  async addTag(event) {
    event.preventDefault();

    // Collect input values
    const tagInput = this.element.querySelector('input[name="new_tag"]');
    const selectedTags = Array.from(
      this.element.querySelectorAll('input[name="tags[]"]:checked'),
    ).map((checkbox) => checkbox.value);

    const itemData = {
      tag_name: tagInput.value,
      selected_tags: selectedTags,
    };

    try {
      const response = await fetch("/estimates/add_tag", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
        },
        body: JSON.stringify(itemData),
      });

      // Check if the response is ok
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      // Get the response text and insert it into the DOM
      const text = await response.text();
      document.body.insertAdjacentHTML("beforeend", text); // Insert the new HTML
      tagInput.value = "";
    } catch (error) {
      console.log(error);
    }
  }
}
